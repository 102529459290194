import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Auth.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("../pages/auth/login.vue"),
      },
    ],
  },
  {
    path: "/main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/innerSearch",
        name: "InnerSearch",
        component: () => import("../pages/inner/innerSearch.vue"),
      },
      {
        path: "/outerSearch",
        name: "OuterSearch",
        component: () => import("../pages/outer/outerSearch.vue"),
      },
      {
        path: "/serialNumberSearch",
        name: "SerialNumberSearch",
        component: () => import("../pages/serialNumber/serialNumberSearch.vue"),
      },
      {
        path: "/permissions",
        name: "Permissions",
        component: () => import("../pages/misc/permissions.vue"),
      },
      {
        path: "/*",
        name: "*",
        component: () => import("../pages/misc/progressing.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
