const reservedSeat = (val) => {
  switch (val) {
    case 0:
      return '未匯款'
    case 1:
      return '確認已匯款'
    default:
      return '-'
  }
}

const location = (val) => {
  switch (val) {
    case 1:
      return '江子翠'
    case 2:
      return '新莊'
    case 3:
      return '林口'
    default:
      return '-'
  }
}

const identityType = (val) => {
  switch (val) {
    case 8:
      return '會友(已參加小組)'
    case 9:
      return '會友(未參加小組)'
    case 7:
        return '新朋友(第一次參加)'
    case 2:
      return '全職同工或傳道'
    case 3:
      return '牧師'
    case 4:
      return '師母'
    case 5:
        return '長老'
    case 6:
      return '非本堂會友' 
    default:
      return '-'
  }
}

export default {
  reservedSeat,
  identityType,
  location
}
