import BaseAPI from "./base";

const serialNumberAPI = {
  getList(payload) {
    return BaseAPI("post", `/SerialNumber/QuerySerialNumberSwitch`, payload);
  },
  addSerialNumber(payload) {
    return BaseAPI("post", `/SerialNumber/AddSerialNumberSwitch`, payload);
  },
  modifySerialNumber(payload) {
    return BaseAPI("put", `/SerialNumber/ModifySerialNumberSwitch`, payload);
  },
  deleteSerialNumber(payload) {
    return BaseAPI("delete", `/SerialNumber/DeleteSerialNumberSwitch`, payload);
  },
  getTicketFee(payload) {
    return BaseAPI("post", `/SerialNumber/GetTicketFee`, payload);
  },
};

export default {
  serialNumberAPI,
};
