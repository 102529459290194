import auth from "./auth";
import common from "./common";
import serialNumber from "./serialNumber";
import outer from './outer';
import inner from './inner'

export default {
  auth,
  common,
  serialNumber,
  outer,
  inner
};
