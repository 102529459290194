import axios from "axios";
import { toLogin, dev, prod } from "./utils";
import store from "../store";
import router from "../router";

const successButErrorHandle = (data) => {
  if (!data) {
    toLogin();
    store.dispatch("auth/setAuth", {
      token: "",
      isLogin: false,
    });
    store.dispatch("notification/openNotification", {
      msg: "身分驗證失效，請重新登入",
      color: "gray",
      timeout: 5000,
      showClose: true,
    });
  }
};

const errorHandle = (status, msg) => {
  switch (status) {
    case 400:
      store.dispatch("notification/openNotification", {
        msg: "資料錯誤",
        color: "gray",
        timeout: 5000,
        showClose: true,
      });
      break;
    case 401:
      if (router.currentRoute !== "Login") {
        store.dispatch("auth/setAuth", {
          token: "",
          isLogin: false,
        });
        store.dispatch("notification/openNotification", {
          msg: "身分驗證失效，請重新登入",
          color: "gray",
          timeout: 5000,
          showClose: true,
        });
        setTimeout(() => {
          toLogin();
        }, 1000);
      }
      break;
    default:
      console.log(msg);
  }
};

let instance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? prod : dev,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.state.auth.token;
    token && (config.headers.Authorization = "Bearer " + token);
    if (config.params && config.params.responseType)
      config.responseType = config.params.responseType;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    successButErrorHandle(response.data);
    return response;
  },
  (err) => {
    const { response } = err;
    if (response) {
      errorHandle(response.status, response.data.err);
      return Promise.reject(err);
    } else {
      if (!window.navigator.onLine) {
        store.dispatch("notification/openNotification", {
          msg: "網路有問題, 重新整理試試",
          color: "gray",
          timeout: 5000,
          showClose: true,
        });
      } else {
        return Promise.reject(err);
      }
    }
  }
);

export default (method, url, data = null, responseType = null) => {
  method = method.toLowerCase();
  switch (method) {
    case "get":
      return instance.get(url, { params: data });
    case "post":
      return instance.post(url, data);
    case "put":
      return instance.put(url, data);
    case "patch":
      return instance.patch(url, data);
    case "delete":
      return instance.delete(url, { data: data });
    case "export":
      return instance.post(url, data, { params: responseType });
    default:
      console.log("unknown method");
  }
};
