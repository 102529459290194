import BaseAPI from "./base";

const outerAPI = {
  getList(payload) {
    return BaseAPI("post", `/RegistrationOuter/QueryRegistrationOuter`, payload);
  },
  addOuter(payload) {
    return BaseAPI("post", `/RegistrationOuter/AddRegistrationOuter`, payload);
  },
  modifyOuter(payload) {
    return BaseAPI("put", `/RegistrationOuter/ModifyRegistrationOuter`, payload);
  },
  deleteOuter(payload) {
    return BaseAPI("delete", `/RegistrationOuter/DeleteRegistrationOuter`, payload);
  },
  exportList(payload) {
    return BaseAPI("export", `/RegistrationOuter/ExportRegistrationOuter`, payload, {responseType: 'blob'});
  },
  sendMessage(payload) {
    return BaseAPI("post", `/RegistrationOuter/RegistrationOuterSendMessage`, payload);
  },
  getSMS(payload) {
    return BaseAPI("post", `/RegistrationOuter/GetMarriageSms`, payload);
  },
};

export default {
  outerAPI,
};
