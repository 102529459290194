import BaseAPI from "./base";

const innerAPI = {
  getList(payload) {
    return BaseAPI("post", `/Registration/QueryRegistration`, payload);
  },
  addInner(payload) {
    return BaseAPI("post", `/Registration/AddRegistration`, payload);
  },
  modifyInner(payload) {
    return BaseAPI("put", `/Registration/ModifyRegistration`, payload);
  },
  deleteInner(payload) {
    return BaseAPI("delete", `/Registration/DeleteRegistration`, payload);
  },
  exportList(payload) {
    return BaseAPI("export", `/Registration/ExportRegistration`, payload, {responseType: 'blob'});
  },
};

export default {
  innerAPI,
};
