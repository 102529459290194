import BaseAPI from "./base";

const commonAPI = {
  getClcList(data) {
    return BaseAPI("get", `/ClcForm/SelectChurch`, data);
  },
  getClcGroupList(data) {
    return BaseAPI("get", `/ClcForm/SelectGroup`, data);
  },
  getIdentityType(data) {
    return BaseAPI("get", `/ClcForm/GetIdentityType`, data);
  },
  getSelectTicket(data) {
    return BaseAPI("get", `/ClcForm/SelectTicket`, data);
  },
};

export default {
  commonAPI,
};
